<template>
  <div class="container-fluid">
    <!--page header start -->
        <div class="row">
            <div class="col-md-12">
                <div class="mb-2 clearfix">
                    <div class="d-flex justify-content-between align-items-center display-options" id="displayOptions">               
                        <div class="d-block d-md-inline-block">
                            <div class="btn-group d-inline-block float-md-left mr-1 mb-1 ">
                                <b-form-select v-model="filtre_displayed_messages"  style="height: 40px; width: 260px; border-radius: 5px; box-shadow: none">
                                    <b-form-select-option value="non-lues">Messages non lues</b-form-select-option>
                                    <b-form-select-option value="deja-lus">Messages déjà lus</b-form-select-option>
                                    <b-form-select-option value="tous-les-messages">Tous les messages</b-form-select-option>
                                </b-form-select>
                            </div>
                            <SearchForm v-model="search" />
                            <div class="btn-group">
                                <b-button variant="danger" @click.prevent="showDeleteMolal">Tout Supprimer</b-button
        >
                            </div>
                        </div>
                        <div class="float-md-right d-flex">
                            <!--<paginatorTop :offset="offset" :libelle='$t("data.logements")' :total="trueItems.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" class="mr-2 d-flex justify-content-center align-items-center" />-->
                        </div>
                    </div>
                </div>
                <div class="separator mb-20"></div>
                <b-overlay :show="showOverlay" rounded="sm">
                    <b-alert variant="info" class="text-center" show v-if="!tableItems.length">
                        <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                        <span class="h4 d-inline-flex ml-2">Aucun message pour le moment</span>
                    </b-alert> 
                    <b-row v-else>
                        <!--<b-table class="b-table" :items="tableItems" :fields="fields" fixed>
                            <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">
                                
                                
                                <b-checkbox
                                :checked="tableItems[data.index].isSelected"
                                :key="index"
                                @change="selectRowHandler(data)"
                                ></b-checkbox>
                                <div :key="index" v-else-if="field.type === 'edit'">
                                <b-button @click="editRowHandler(data, field)" :disabled="disableButton(data)">
                                    <span v-if="!tableItems[data.index].isEdit">Edit</span>
                                    <span v-else>Done</span>
                                </b-button>
                                <b-button
                                    v-if="!tableItems[data.index].isEdit"
                                    class="delete-button"
                                    variant="danger"
                                    @click="removeRowHandler(data.index)"
                                    >Remove</b-button
                                >
                                </div>
                                <b-form-input
                                v-else-if="field.type && tableItems[data.index].isEdit"
                                :key="index"
                                :type="field.type"
                                :value="tableItems[data.index][field.key]"
                                @blur="(e) => inputHandler(e, data.index, field)"
                                :required="field.required"
                                :pattern="field.pattern"
                                :state="tableItems[data.index].validity[field.key]"
                                ></b-form-input>
                                <span :key="index" v-else>{{ data.value }}</span>
                            </template>
                        </b-table>-->
                         <b-table class="b-table"  v-for="(user, i) in tableItems" :key="i" striped hover :items="tableItems" :fields="fields" fixed outlined>
                             <template #cell(action)="data">
                                 <b-button @click="editRowHandler(data, field)">
                                    <span>detail</span>
                                </b-button>
                                <b-button class="delete-button" variant="danger" @click="removeRowHandler(data.index)">Supprimer</b-button>
                            </template>
                         </b-table>
                    </b-row>
                    <hr>
                    <div class="d-flex justify-content-between align-items-start">
                        <paginator :offset="offset" :total="trueItems.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" /> 
                    </div>
                </b-overlay>
            </div>
        </div>
  </div>
</template>
<script>
import MessageTable from '@/components/tables/MessageTable.vue';
import SearchForm from "@/components/parts/SearchForm.vue";
  const php  = require ( 'phpjs' ) ; 
export default {
  name: "Messages",
  components: {
      SearchForm,
      MessageTable
  },
  data: () => ({
    showOverlay:false,
    submitted:false,
    filtre_displayed_messages: 'tous-les-messages', 
    search: null,
    tableItems:[
  {
    "id": 1,
    "name": "Leanne Graham",
    "username": "Bret",
    "email": "Sincere@april.biz",
    "address": {
      "street": "Kulas Light",
      "suite": "Apt. 556",
      "city": "Gwenborough",
      "zipcode": "92998-3874",
      "geo": {
        "lat": "-37.3159",
        "lng": "81.1496"
      }
    },
    "phone": "1-770-736-8031 x56442",
    "website": "hildegard.org",
    "company": {
      "name": "Romaguera-Crona",
      "catchPhrase": "Multi-layered client-server neural-net",
      "bs": "harness real-time e-markets"
    }
  },
  {
    "id": 2,
    "name": "Ervin Howell",
    "username": "Antonette",
    "email": "Shanna@melissa.tv",
    "address": {
      "street": "Victor Plains",
      "suite": "Suite 879",
      "city": "Wisokyburgh",
      "zipcode": "90566-7771",
      "geo": {
        "lat": "-43.9509",
        "lng": "-34.4618"
      }
    },
    "phone": "010-692-6593 x09125",
    "website": "anastasia.net",
    "company": {
      "name": "Deckow-Crist",
      "catchPhrase": "Proactive didactic contingency",
      "bs": "synergize scalable supply-chains"
    }
  },
  {
    "id": 3,
    "name": "Clementine Bauch",
    "username": "Samantha",
    "email": "Nathan@yesenia.net",
    "address": {
      "street": "Douglas Extension",
      "suite": "Suite 847",
      "city": "McKenziehaven",
      "zipcode": "59590-4157",
      "geo": {
        "lat": "-68.6102",
        "lng": "-47.0653"
      }
    },
    "phone": "1-463-123-4447",
    "website": "ramiro.info",
    "company": {
      "name": "Romaguera-Jacobson",
      "catchPhrase": "Face to face bifurcated interface",
      "bs": "e-enable strategic applications"
    }
  },
  {
    "id": 4,
    "name": "Patricia Lebsack",
    "username": "Karianne",
    "email": "Julianne.OConner@kory.org",
    "address": {
      "street": "Hoeger Mall",
      "suite": "Apt. 692",
      "city": "South Elvis",
      "zipcode": "53919-4257",
      "geo": {
        "lat": "29.4572",
        "lng": "-164.2990"
      }
    },
    "phone": "493-170-9623 x156",
    "website": "kale.biz",
    "company": {
      "name": "Robel-Corkery",
      "catchPhrase": "Multi-tiered zero tolerance productivity",
      "bs": "transition cutting-edge web services"
    }
  },
  {
    "id": 5,
    "name": "Chelsey Dietrich",
    "username": "Kamren",
    "email": "Lucio_Hettinger@annie.ca",
    "address": {
      "street": "Skiles Walks",
      "suite": "Suite 351",
      "city": "Roscoeview",
      "zipcode": "33263",
      "geo": {
        "lat": "-31.8129",
        "lng": "62.5342"
      }
    },
    "phone": "(254)954-1289",
    "website": "demarco.info",
    "company": {
      "name": "Keebler LLC",
      "catchPhrase": "User-centric fault-tolerant solution",
      "bs": "revolutionize end-to-end systems"
    }
  },
  {
    "id": 6,
    "name": "Mrs. Dennis Schulist",
    "username": "Leopoldo_Corkery",
    "email": "Karley_Dach@jasper.info",
    "address": {
      "street": "Norberto Crossing",
      "suite": "Apt. 950",
      "city": "South Christy",
      "zipcode": "23505-1337",
      "geo": {
        "lat": "-71.4197",
        "lng": "71.7478"
      }
    },
    "phone": "1-477-935-8478 x6430",
    "website": "ola.org",
    "company": {
      "name": "Considine-Lockman",
      "catchPhrase": "Synchronised bottom-line interface",
      "bs": "e-enable innovative applications"
    }
  },
  {
    "id": 7,
    "name": "Kurtis Weissnat",
    "username": "Elwyn.Skiles",
    "email": "Telly.Hoeger@billy.biz",
    "address": {
      "street": "Rex Trail",
      "suite": "Suite 280",
      "city": "Howemouth",
      "zipcode": "58804-1099",
      "geo": {
        "lat": "24.8918",
        "lng": "21.8984"
      }
    },
    "phone": "210.067.6132",
    "website": "elvis.io",
    "company": {
      "name": "Johns Group",
      "catchPhrase": "Configurable multimedia task-force",
      "bs": "generate enterprise e-tailers"
    }
  },
  {
    "id": 8,
    "name": "Nicholas Runolfsdottir V",
    "username": "Maxime_Nienow",
    "email": "Sherwood@rosamond.me",
    "address": {
      "street": "Ellsworth Summit",
      "suite": "Suite 729",
      "city": "Aliyaview",
      "zipcode": "45169",
      "geo": {
        "lat": "-14.3990",
        "lng": "-120.7677"
      }
    },
    "phone": "586.493.6943 x140",
    "website": "jacynthe.com",
    "company": {
      "name": "Abernathy Group",
      "catchPhrase": "Implemented secondary concept",
      "bs": "e-enable extensible e-tailers"
    }
  },
  {
    "id": 9,
    "name": "Glenna Reichert",
    "username": "Delphine",
    "email": "Chaim_McDermott@dana.io",
    "address": {
      "street": "Dayna Park",
      "suite": "Suite 449",
      "city": "Bartholomebury",
      "zipcode": "76495-3109",
      "geo": {
        "lat": "24.6463",
        "lng": "-168.8889"
      }
    },
    "phone": "(775)976-6794 x41206",
    "website": "conrad.com",
    "company": {
      "name": "Yost and Sons",
      "catchPhrase": "Switchable contextually-based project",
      "bs": "aggregate real-time technologies"
    }
  },
  {
    "id": 10,
    "name": "Clementina DuBuque",
    "username": "Moriah.Stanton",
    "email": "Rey.Padberg@karina.biz",
    "address": {
      "street": "Kattie Turnpike",
      "suite": "Suite 198",
      "city": "Lebsackbury",
      "zipcode": "31428-2261",
      "geo": {
        "lat": "-38.2386",
        "lng": "57.2232"
      }
    },
    "phone": "024-648-3804",
    "website": "ambrose.net",
    "company": {
      "name": "Hoeger LLC",
      "catchPhrase": "Centralized empowering task-force",
      "bs": "target end-to-end models"
    }
  }
    ],
    fields: [
        { key: "numero", label: "" },
        { key: "selectRow", label: "" },
        { key: "name", label: "Name", type: "text", required: true },
        { key: "email", label: "Email", type: "email", required: true, pattern: ".+@bootstrapvue\\.com" },
        { key: "phone", label: "Phone", type: "text" },
        { key: "action", label: "", type: "action" },
    ],


 currentPage: 1,
     perPage: 10,
      messages:[],
     trueMessages:[],
  }),
  computed: {
         /**
         * Elements affichés avec prise en charge de la pagination
         */
        items() { 
            return this.messages/*php.array_slice(this.trueItems, this.offset, this.perPage)*/
        },
        trueItems() {
            let items = this.messages
            /*if (this.filtre_displayed_indexe == 'non-renseigner') {
                items = items.filter(elt => (elt.indexeMois.eau == null || elt.indexeMois.energie == null))
            }
            if (this.filtre_displayed_indexe == 'deja-renseigner') {
                items = items.filter(elt => (elt.indexeMois.eau != null || elt.indexeMois.energie != null))
            }*/
            return items
        },
        offset() {
            return 2/*(this.currentPage * this.perPage) - this.perPage*/
        },

    },
    mounted() {

    },
    methods: {

        editRowHandler(data) {
      if (this.tableItems[data.index].isEdit) {
        this.$emit("submit", this.tableItems[data.index]);
      }
      this.tableItems[data.index].isEdit = !this.tableItems[data.index].isEdit;
      this.tableItems[data.index].validity = {};
      this.$set(this.tableItems, data.index, this.tableItems[data.index]);
    },
    inputHandler(e, index, field) {
      if (!e.target.validity.valid ) {
        this.tableItems[index].validity[field.key] = false;
        this.$set(this.tableItems, index, this.tableItems[index]);
      } else {
        if (field.required || field.patter) {
          this.tableItems[index].validity[field.key] = true;
        }
        this.tableItems[index][field.key] = e.target.value;
        this.$set(this.tableItems, index, this.tableItems[index]);
        this.$emit("input", this.tableItems);
      }
    },
    addRowHandler() {
      const newRow = this.fields.reduce(
        (a, c) => ({ ...a, [c.key]: null }),
        {}
      );
      newRow.isEdit = true;
      this.tableItems.unshift(newRow);
      this.$emit("input", this.tableItems);
    },
    removeRowHandler(index) {
      this.tableItems = this.tableItems.filter((item, i) => i !== index);
      this.$emit("input", this.tableItems);
      this.$emit("remove", this.tableItems[index]);
    },
    removeRowsHandler() {
      const selectedItems = this.tableItems.filter((item) => item.isSelected);
      this.tableItems = this.tableItems.filter((item) => !item.isSelected);
      this.$emit("input", this.tableItems);
      this.$emit("remove", selectedItems);
    },
    selectRowHandler(data) {
      this.tableItems[data.index].isSelected = !this.tableItems[data.index]
        .isSelected;
    },
    disableButton(data) {
      return Object.values(data.item.validity).some(valid => !valid);
    },


        /*methode de suppression d'un ou plusieurs messaages */
        showDeleteMolal(){
            console.log("entrée suppression ok")
        },
        async handleUpdateUser(user) {
            if (user.id) {
                await updateUser(user);
            } else {
                await addUser(user);
            }
        },
    async handleRemoveUser(user) {
      if (user.length > 0) {
        await user.map(async (item) => {
          await deleteUser(item.id);  
        })
      } else {
        await deleteUser(user.id);
      }
    }

    }

};
</script>